import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'gatsby';
import './Header.css';

class Header extends Component {
  render() {
    return (
      <Navbar collapseOnSelect expand="md" bg="light" variant="light" fixed="top">
        <Navbar.Brand href="/">
          <img
            src="/logos/logo-1024.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Parsed Logo"
          />
          Parsed
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" />
          <Nav>
            <Navbar.Text>
              <Link to="/blog" style={{ marginRight: 10 }}>
                Blog
              </Link>
            </Navbar.Text>
            <Navbar.Text>
              <Link to="/#contact">Contact Us</Link>
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
